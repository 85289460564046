<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Breaking Quiz Management" class="mb-3" />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end import mb-1">
      <b-button pill variant="outline-info">
        <a href="https://docs.google.com/spreadsheets/d/1P5fHWJ8l8ye_QK8aiJrjTaD1CKTFwD5LCZxoS1jttuA/edit?gid=0#gid=0"
          target="_blank">?</a>
      </b-button>
      <label for="upload" class="btn btn-import btn-primary">
        <span>IMPORT</span>
        <input type="file" id="upload" ref="files" style="display: none" @change="handleFilesUpload">
      </label>
      <p class="rows-uploaded">{{ excelData.length + ' rows uploaded' }}</p>
      <b-button v-if="isReadyImport" variant="success" class="btn-add mr-1" size="sm"
        @click="updateLoadData">SAVE</b-button>
      <b-button v-if="isReadyImport" variant="success" class="btn-add" size="sm" @click="replaceData">REPLACE</b-button>
    </div>
    <data-create-popup title="Create Quiz" :schema="create_schema" :default-data="default_data"
      :create-data-fn="createItem" @create="$refs.group_list.getList()" />
    <data-table-ssr id="group_list" ref="group_list" :columns="fields" :get-list-fn="getList" :to-edit-fn="toEditPage"
      :delete-row-fn="deleteItem" />
  </div>
</template>

<script>
import service from "../service";
import default_data from "../default_data";
import XLSX from 'xlsx'
const VALID_FILE_EXTENSIONS = 'xlsx'
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Question', field: 'question', validate: { required: true } },
    ]
  },
];

const fields = [
  { label: 'Questions', field: 'question' },
  { label: 'Answer1', field: 'answer1', value_handler: (model) => { return model.answers[0].content } },
  { label: 'Answer2', field: 'answer2', value_handler: (model) => { return model.answers[1].content } },
  { label: 'Answer3', field: 'answer3', value_handler: (model) => { return model.answers[2].content } },
  { label: 'Answer4', field: 'answer4', value_handler: (model) => { return model.answers[3].content } },
  { label: 'CorrectAnswer', field: 'correctanswer', value_handler: (model) => { return model.answers.find(item => item.is_correct === true)?.content } },
];
export default {

  data() {
    return {
      courses: [],
      default_data: { ...default_data },
      create_schema,
      fields,
      excelData: [],
      isReadyImport: false
    };
  },

  methods: {
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'breaking-quiz-detail', params: { id: _id } });
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
    },
    handleFilesUpload(e) {
      var input = e.target;
      var reader = new FileReader();
      if (!input.files[0] || !this.checkValidFileExtension(input.files[0].name)) {
        return this.$store.dispatch("pushErrorNotify", {
          text: "File upload is invalid!",
        })
      }
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: 'binary' });
        wb.SheetNames.forEach((sheetName) => {
          var rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          this.excelData = rowObj
        })
      };
      reader.readAsBinaryString(input.files[0]);
      this.isReadyImport = true;
    },

    checkValidFileExtension(fileName) {
      if (!fileName) { return false }
      return fileName.substr(fileName.length - VALID_FILE_EXTENSIONS.length, VALID_FILE_EXTENSIONS.length).toLowerCase() === VALID_FILE_EXTENSIONS
    },

    async updateLoadData() {
      this.isLoading = true
      const dataUpload = this.excelData.map(usr => {
        return {
          _id: usr._id || null,
          question: usr['Questions'],
          answers: [
            { content: usr['Answer1'], is_correct: usr['Answer1'] === usr['CorrectAnswer'] },
            { content: usr['Answer2'], is_correct: usr['Answer2'] === usr['CorrectAnswer'] },
            { content: usr['Answer3'], is_correct: usr['Answer3'] === usr['CorrectAnswer'] },
            { content: usr['Answer4'], is_correct: usr['Answer4'] === usr['CorrectAnswer'] },
          ],
        }
      })
      if (!dataUpload.length) {
        this.isLoading = false
        return this.$store.dispatch("pushErrorNotify", {
          text: "users data is empty!",
        })
      }

      let res = await service.import(dataUpload);
      this.isLoading = false
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Import Successfully!" });
        this.excelData = [];
      }
      this.$router.go()
    },
    async replaceData() {
      this.isLoading = true
      const dataUpload = this.excelData.map(usr => {
        return {
          question: usr['Questions'],
          answers: [
            { content: usr['Answer1'], is_correct: usr['Answer1'] === usr['CorrectAnswer'] },
            { content: usr['Answer2'], is_correct: usr['Answer2'] === usr['CorrectAnswer'] },
            { content: usr['Answer3'], is_correct: usr['Answer3'] === usr['CorrectAnswer'] },
            { content: usr['Answer4'], is_correct: usr['Answer4'] === usr['CorrectAnswer'] },
          ],
        }
      })
      if (!dataUpload.length) {
        this.isLoading = false
        return this.$store.dispatch("pushErrorNotify", {
          text: "users data is empty!",
        })
      }

      let res = await service.replace(dataUpload);
      this.isLoading = false
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Replace Successfully!" });
        this.excelData = [];
      }
      this.$router.go()
    }
  },
};
</script>
<style lang="scss" scoped>
.btn-import {
  margin-left: 5px;
  display: table;
  margin-bottom: 0;

  span {
    display: table-cell;
  }
}

.rows-uploaded {
  margin: auto 5px;
  padding: 10px 5px;
  border: solid 1px;
  border-radius: 10px;
  font-weight: 800;
}
</style>